import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { SimpleGuard } from '@delon/auth';
import { environment } from '@env/environment';

// 架构布局组件
import { LayoutDefaultComponent } from '../layout/default/default.component';
// 控制台组件
import { MainComponent } from './main/main.component';
// 登录组件
import { UserLoginComponent } from './login/login.component';
// 授权登录
import { AuthLoginComponent } from './auth-login/auth-login.component';
// 山东通授权登录
import { ShanDongTongLoginComponent } from './shandongtong-login/shandongtong-login.component';
// 山东通授权登录奥链3.0
import { ShanDongTongLogin3Component } from './shandongtong-login3/shandongtong-login3.component';

// 路由守卫 校验登录状态
import { AuthGuard } from '../auth.guard';

// 基础功能路由定义
const routes: Routes = [
  {
    path: 'login',
    component: UserLoginComponent,
    data: { title: '登录' },
  },
  {
    path: 'oauthlogin',
    component: AuthLoginComponent,
    data: { title: '授权登录' },
  },
  {
    path: 'shandongtonglogin',
    component: ShanDongTongLoginComponent,
    data: { title: '山东通授权登录' },
  },
  {
    path: 'no-login',
    component: ShanDongTongLogin3Component,
    data: { title: '山东通授权登录奥链3.0' },
  },
  {
    path: '',
    component: LayoutDefaultComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      { path: '', redirectTo: 'main', pathMatch: 'full', runGuardsAndResolvers: 'always', },
      { path: 'main', component: MainComponent, runGuardsAndResolvers: 'always', data: { title: '控制台', reuse: true, reuseClosable: false } },
      {
        path: 'this-landscape',
        loadChildren: () => import('./this-landscape/this-landscape.module').then(m => m.ThisLandscapeModule),
      },

      {
        path: 'province-index',
        loadChildren: () => import('./province-index/province-index.module').then(m => m.ProvinceIndexModule),
      },
      {
        path: 'exception',
        loadChildren: () => import('./exception/exception.module').then(m => m.ExceptionModule),
      },
      {
        path: 'SysManage',
        loadChildren: () => import('./sys-manage/sys-manage.module').then(m => m.SysManageModule),
      },
      {
        path: 'JobManage',
        loadChildren: () => import('./job-manage/job-manage.module').then(m => m.JobManageModule),
      },
      {
        path: 'DCAS-BasicSetting',
        loadChildren: () =>
          import('./dcas-basic-setting/dcas-basic-setting.module').then(m => m.DCASBasicSettingModule),
      },
      {
        path: 'DCAS-DataCollection',
        loadChildren: () =>
          import('./dcas-data-collection/dcas-data-collection.module').then(m => m.DCASDataCollectionModule),
      },
      {
        path: 'DCAS-DataAnalysis',
        loadChildren: () =>
          import('./dcas-data-analysis/dcas-data-analysis.module').then(m => m.DCASDataAnalysisModule),
      },
      {
        path: 'DCAS-DataReport',
        loadChildren: () => import('./dcas-data-report/dcas-data-report.module').then(m => m.DCASDataReportModule),
      },
      {
        path: 'DCAS-DepBasicSetting',
        loadChildren: () =>
          import('./dcas-dep-basic-setting/dcas-dep-basic-setting.module').then(m => m.DCASDepBasicSettingModule),
      },
      {
        path: 'PostManage',
        loadChildren: () => import('./post-manage/post-manage.module').then(m => m.PostManageModule),
      },
      {
        path: 'BasicSetting',
        loadChildren: () => import('./basic-setting/basic-setting.module').then(m => m.BasicSettingModule),
      },
      {
        path: 'OrgModel',
        loadChildren: () => import('./org-model/org-model.module').then(m => m.OrgModelModule),
      },
      {
        path: 'comprehensiveAnalysis',
        loadChildren: () =>
          import('./comprehensive-analysis/comprehensive-analysis.module').then(m => m.ComprehensiveAnalysisModule),
      },
      {
        path: 'big-data-landscape',
        loadChildren: () =>
          import('./big-data-landscape/big-data-landscape.module').then(m => m.BigDataLandscapeModule),
      },
      {
        path: 'thematicAnalysis',
        loadChildren: () => import('./thematic-analysis/thematic-analysis.module').then(m => m.ThematicAnalysisModule),
      },
      {
        path: 'MonthlyAnalysis',
        loadChildren: () => import('./monthly-analysis/monthly-analysis.module').then(m => m.MonthlyAnalysisModule),
      },
      {
        path: 'TempAnalysis',
        loadChildren: () => import('./temp-analysis/temp-analysis.module').then(m => m.TempAnalysisModule),
      },
      {
        path: 'NewProjectManagement',
        loadChildren: () =>
          import('./new-project-management/new-project-management.module').then(m => m.NewProjectManagementModule),
      },
      {
        path: 'NewProjectManagements',
        loadChildren: () =>
          import('./new-project-managements/new-project-managements.module').then(m => m.NewProjectManagementsModule),
      },
      {
        path: 'BigDataAnalysis',
        loadChildren: () => import('./big-data-analysis/big-data-analysis.module').then(m => m.BigDataAnalysisModule),
      },
      {
        path: 'EconomicAnalysis',
        loadChildren: () => import('./economic-analysis/economic-analysis.module').then(m => m.EconomicAnalysisModule),
      },
      {
        path: 'IndustryAnalysis',
        loadChildren: () => import('./industry-analysis/industry-analysis.module').then(m => m.IndustryAnalysisModule),
      },
      {
        path: 'BusinessData',
        loadChildren: () => import('./business-data/business-data.module').then(m => m.BusinessDataModule),
      },
      {
        path: 'SpatialDataOverlay',
        loadChildren: () =>
          import('./spatial-data-overlay/spatial-data-overlay.module').then(m => m.SpatialDataOverlayModule),
      },
      {
        path: 'SystemNotice',
        loadChildren: () => import('./system-notice/system-notice.module').then(m => m.SystemNoticeModule),
      },
      {
        path: 'reserve-management',
        loadChildren: () =>
          import('./reserve-management/reserve-management.module').then(m => m.ReserveManagementModule),
      },
      {
        path: 'eco-analysis',
        loadChildren: () => import('./eco-analysis/eco-analysis.module').then(m => m.EcoAnalysisModule),
      },
      {
        path: 'portal-management',
        loadChildren: () => import('./portal-management/portal-management.module').then(m => m.PortalManagementModule),
      },
      {
        path: 'chainquery',
        loadChildren: () => import('./chainquery/chainquery.module').then(m => m.ChainqueryModule),
      },
      {
        path: 'market-value-target',
        loadChildren: () => import('./market-value-target/market-value-target.module').then(m => m.MarketValueTargetModule),
      },
      {
        path: 'chaindynamic',
        loadChildren: () => import('./chaindynamic/chaindynamic.module').then(m => m.ChaindynamicModule),
      },
      {
        path: 'chaincommand',
        loadChildren: () => import('./chaincommand/chaincommand.module').then(m => m.ChaincommandModule),
      },
      {
        path: 'chainmap',
        loadChildren: () => import('./chainmap/chainmap.module').then(m => m.ChainmapModule),
      },
      {
        path: 'chainanalize',
        loadChildren: () => import('./chainanalize/chainanalize.module').then(m => m.ChainanalizeModule),
      },
      {
        path: 'law',
        loadChildren: () => import('./law/law.module').then(m => m.LawModule),
      },
      {
        path: 'chainconfig',
        loadChildren: () => import('./chainconfig/chainconfig.module').then(m => m.ChainconfigModule),
      },
      {
        path: 'front-page-of-chain',
        loadChildren: () => import('./front-page-of-chain/front-page-of-chain.module').then(m => m.FrontPageOfChainModule),
      },
      {
        path: 'ChainAnalysis',
        loadChildren: () => import('./chain-analysis/chain-analysis.module').then(m => m.ChainAnalysisModule),
      },
      {
        path: 'ChainBasic',
        loadChildren: () => import('./chain-basic/chain-basic.module').then(m => m.ChainBasicModule),
      },
      {
        path: 'ChainBasicFile',
        loadChildren: () => import('./chain-basic-file/chain-basic-file.module').then(m => m.ChainBasicFileModule),
      },
      {
        path: 'ChainData',
        loadChildren: () => import('./chain-data/chain-data.module').then(m => m.ChainDataModule),
      },
      {
        path: 'ChainTask',
        loadChildren: () => import('./chain-task/chain-task.module').then(m => m.ChainTaskModule),
      },

      {
        path: 'data-monitoring',
        loadChildren: () => import('./data-monitoring/data-monitoring.module').then(m => m.DataMonitoringModule),
      },
      {
        path: 'industrial-chain',
        loadChildren: () => import('./industrial-chain/industrial-chain.module').then(m => m.IndustrialChainModule),
      },
      {
        path: 'eco-summary-municipal',
        loadChildren: () => import('./eco-summary-municipal/eco-summary-municipal.module').then(m => m.EcoSummaryMunicipalModule),
      },
      {
        path: 'electricity-tax-analysis',
        loadChildren: () => import('./electricity-tax-analysis/electricity-tax-analysis.module').then(m => m.ElectricityTaxAnalysisModule),
      },
      {
        path: 'thirdPlatform',
        loadChildren: () => import('./third-platform/third-platform.module').then(m => m.ThirdPlatformModule),
      },
      {
        path: 'Ip-Files',
        loadChildren: () => import('./ip-files/ip-files.module').then(m => m.IpFilesModule),
      },
      {
        path: 'EnterCloud',
        loadChildren: () => import('./enter-cloud/enter-cloud.module').then(m => m.EnterCloudModule),
      },
      {
        path: 'GoverWithSincerity',
        loadChildren: () => import('./gover-with-sincerity/gover-with-sincerity.module').then(m => m.GoverWithSincerityModule),
      },
      {
        path: 'IndustryStatistics',
        loadChildren: () => import('./industry-statistics/industry-statistics.module').then(m => m.IndustryStatisticsModule),
      },
      {
        path: 'policy-audit',
        loadChildren: () => import('./policy-audit/policy-audit.module').then(m => m.PolicyAuditModule),
      }
    ],
  },
  { path: '**', redirectTo: 'exception/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      scrollPositionRestoration: 'top',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class RouteRoutingModule {}
