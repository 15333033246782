import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WarningOutline } from '@ant-design/icons-angular/icons';
import { LoadingService } from '@delon/abc';
import { NzMessageService, NzModalService } from 'ng-zorro-antd';
import { ToolsService } from '../../services/tools.service';

@Component({
  selector: 'app-shandongtong-login3',
  templateUrl: './shandongtong-login3.component.html',
  styles: []
})
export class ShanDongTongLogin3Component implements OnInit {
  viewRef: 'commonError';
  errorMsg: string;
  isLoading = false;
  UUID = '';
  WarningOutline = WarningOutline;

  constructor(
    private loadingSrv: LoadingService,
    private http: HttpClient,
    private msg: NzMessageService,
    private router: Router,
    private modalSrv: NzModalService,
    private toolsService: ToolsService,
    private message: NzMessageService,
  ) {
  }

  ngOnInit() {
    // 显示加载动画
    this.loadingSrv.open({ type: 'icon' });
    const params = new URLSearchParams(window.location.search);
    // 山东通平台用户授权code，点击图标跳转到该页面，带过来的query参数。
    const code = params.get('code');
    // 组织id，需要AES加密，取自配置文件
    let corpID = undefined;
    // 应用id，需要AES加密，取自配置文件
    let agentId = undefined;
    // 应用的凭证秘钥，需要AES加密，取自配置文件
    let secret = undefined;
    // 对应账套识别码，取自配置文件
    let cAccSignId = undefined;
    // auth微服务地址，取自配置文件
    let authMicroServicesUrl = undefined;
    // 山东通平台地址，取自配置文件
    let shanDongTongUrl = undefined;
    // 站点uuid
    let cSiteUuid = undefined;

    console.log("读配置前cAccSignId:" + cAccSignId)

    // 读取配置文件
    this.http.get('assets/project-info.json').subscribe(
      (res: any) => {
        // 存储系统设置信息
        corpID = res.shandongtong3Config.corpID;
        agentId = res.shandongtong3Config.agentId;
        secret = res.shandongtong3Config.secret;
        cAccSignId = res.shandongtong3Config.cAccSignId;
        authMicroServicesUrl = res.shandongtong3Config.authMicroServicesUrl;
        shanDongTongUrl = res.shandongtong3Config.shanDongTongUrl;
        cSiteUuid = res.shandongtong3Config.cSiteUuid;

        console.log("读配置后cAccSignId:" + cAccSignId)

        // 缺少 code 参数则中断流程
        if (!code) {
          this.viewRef = 'commonError';
          this.errorMsg = '参数错误';
          this.loadingSrv.close();
          return 
        }

        this.http.post('/cnic-auth/ZwzqAction/aolian3Login4OAuthTokenShanDongTong', {
          "code" : code,
          "corpID" : corpID,
          "agentId" : agentId,
          "secret" : secret,
          "cAccSignId" : cAccSignId,
          "authMicroServicesUrl" : authMicroServicesUrl,
          "shanDongTongUrl" : shanDongTongUrl,
        }).subscribe((res: any) => {
          if (res.code === 1) {
            const loginToken = res.data;
            window.open("https://llh.aolianyun.com/#/sdt-login?t=" + loginToken + "&cSiteUuid=" + cSiteUuid);
          } else {
            this.viewRef = 'commonError';
            this.errorMsg = res.msg;
          }
        }, () => {
          this.viewRef = 'commonError';
          this.errorMsg = '参数错误';
        }, () => {
          this.loadingSrv.close();
        });
      }
    );

  }
}
